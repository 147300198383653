import React from 'react';
import "../Layouts/test.css";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Aboutus from "../images/aboutus.jpg";

import Conc from './Conc';


import {
  BrowserRouter as Router,
  Route, Link, Switch
} from "react-router-dom";

class About extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div>
        <header className="page-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="#home">Trinabh Payment Services Pvt Ltd</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>

            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar>
        </header>



        <section id="about">
      <div class="container" data-aos="fade-up">
      <header class="section-header">
          <h3>Market Analysis & Advisory</h3>
        </header>
        
        <div class="row about-container">


        </div>

        <div class="row about-extra">
          <div class="col-lg-6" data-aos="fade-right">
            <img src="assets/img/Maketanalysis.png" height="100px" width="700px" class="img-fluid" alt="" />
          </div>
          <div class="col-lg-6 pt-5 pt-lg-0" data-aos="fade-left">
            <h4>Detailed assessment of your business's target market and competitive landscape within a specific industry.</h4>
            <p>
            We take an idea for a product or service and turns it into a commercially viable reality.This analysis also provides the foundation on which your marketing and sales plan will rest.
            </p>
            <p>
            Competitive analysis: Identifies your competitors and analyzes their strengths and weaknesses.
            </p>
          </div>
        </div>



      </div>
    </section>














    <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 footer-info">
            <h3>TRINABH PAY</h3>
            <p>TRINABH PAY is a premier creative IT solutions & digital marketing company providing top of the line digital experiences using creative and innovative solutions of any complexity to a globally diverse clientele.</p>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="/Disclaimer">Disclaimer Policies</a></li>
              <li><a href="/refund">Security Policies</a></li>
              <li><a href="/Efund">Refund Policies</a></li>
              <li><a href="/terms">Terms and conditions</a></li>
              <li><a href="/privacy">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              10th Floor Office No 1004 NMS ICON 
Plot No 194 Sector 19 Ulwe Navi Mumbai ,Raigad , Maharashtra - 410206<br></br>
                          
              <strong>Email:</strong> trinabhpay@trinabhpay.in<br></br>
            </p>

            <div class="social-links">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>

          </div>

          <div class="col-lg-3 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Maharashtra.</p>
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>TRINABH PAYMENT SERVICES PVT LTD</strong>. All Rights Reserved
      </div>
      <div class="credits">

        Designed by <a href="">Trinabh Payment</a>
      </div>
    </div>
  </footer>










      </div>
    )
  }
}

export default About;