import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';


import Test from './Components/Test';
import Login from './Components/Login';
import Terms from './Components/Terms';
import Privacy from './Components/Privacy';
import Refund from './Components/Refund';
import Disclaimer from './Components/Disclaimer';
import Form from './Components/Form';
import About from './Components/About';
import Contact from './Components/Contact';
import Pricing from './Components/Pricing';
import Rif from './Components/Refundnew';
import Service1Desc from './Components/service1Desc';
import Service2Desc from './Components/service2Desc';
import Service3Desc from './Components/service3Desc';
import Service4Desc from './Components/service4Desc';
import Service5Desc from './Components/service5Desc';
import Service6Desc from './Components/service6Desc';

import Service7Desc from './Components/service7Desc';
import Service8Desc from './Components/service8Desc';
import Service9Desc from './Components/service9Desc';

import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

class App extends React.Component {
  render() {

    return (
      <div>
        
        <Router>
          <div className="App">
            <switch>
            <Route exact path="/" component={Test} />
              <Route path="/about" component={About}/>
              <Route path="/contact" component={Contact}/>
              <Route path="/pricing" component={Pricing}/>
            
              <Route  path="/Terms" component={Terms} />
              <Route  path="/privacy" component={Privacy} />
              <Route  path="/refund" component={Refund} />
              <Route  path="/Disclaimer" component={Disclaimer} />
            
              <Route  path="/form" component={Form} />
              <Route  path="/Efund" component={Rif}/>
              <Route exact path="/login" component={Login} />

              <Route exact path="/Service1Desc" component={Service1Desc} />
              <Route exact path="/Service2Desc" component={Service2Desc} />
              <Route exact path="/Service3Desc" component={Service3Desc} />
              <Route exact path="/Service4Desc" component={Service4Desc} />
              <Route exact path="/Service5Desc" component={Service5Desc} />
              <Route exact path="/Service6Desc" component={Service6Desc} />

              <Route exact path="/Service7Desc" component={Service7Desc} />
              <Route exact path="/Service8Desc" component={Service8Desc} />
              <Route exact path="/Service9Desc" component={Service9Desc} />
              
              
            </switch>
          </div>
        </Router>
      </div>
    )
  }
}

export default App;
