import React from 'react';
import "../Layouts/Login.css";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';


class Login extends React.Component {


  render() {
    return (
      <div>

<header className="page-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="#home">Trinabh Payment Services Pvt Ltd</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>

            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar>
        </header>





        <section id="contact" className="contact">
          <div className="container">

            <div className="section-title aos-init aos-animate" data-aos="fade-up">
              
              <h2>Authentication</h2>
            </div>

            <div className="row">

              <div className="col-lg-4 aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Maharashtra</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>trinabhpay@trinabhpay.in</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+91 5589 55488 55</p>
                  </div>

                </div>

              </div>

              <div className="col-lg-8 mt-5 mt-lg-0 aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">

                <form role="form" className="php-email-form">
                  <div >

                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required="" />
                    </div>

                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input type="email" className="form-control" name="email" id="Password" placeholder="Your Password" required="" />
                    </div>

                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Your Encrypted key" required="" />
                    </div>
                  </div>



                  &nbsp;&nbsp;&nbsp;<button type="submit" className="btn-dark">Log In </button>
                </form>

              </div>

            </div>

          </div>
        </section>


        <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 footer-info">
            <h3>TRINABH PAY</h3>
            <p>TRINABH PAY is a premier creative IT solutions & digital marketing company providing top of the line digital experiences using creative and innovative solutions of any complexity to a globally diverse clientele.</p>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="/Disclaimer">Disclaimer Policies</a></li>
              <li><a href="/refund">Security Policies</a></li>
              <li><a href="/Efund">Refund Policies</a></li>
              <li><a href="/terms">Terms and conditions</a></li>
              <li><a href="/privacy">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              10th Floor Office No 1004 NMS ICON 
Plot No 194 Sector 19 Ulwe Navi Mumbai ,Raigad , Maharashtra - 410206<br></br>
                          
              <strong>Email:</strong> trinabhpay@trinabhpay.in<br></br>
            </p>

            <div class="social-links">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>

          </div>

          <div class="col-lg-3 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Maharashtra.</p>
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>TRINABH PAYMENT SERVICES PVT LTD</strong>. All Rights Reserved
      </div>
      <div class="credits">

        Designed by <a href="">Trinabh Payment</a>
      </div>
    </div>
  </footer>





      </div>
    )
  }
}

export default Login;