import React from 'react';
import "../Layouts/test.css";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



import {
  BrowserRouter as Router,
  Route, Link, Switch
} from "react-router-dom";

class Test extends React.Component {


  render() {
    return (
      <div>

<header className="page-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="#home">Trinabh Payment Services Pvt Ltd</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>

            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar>
        </header>



        <section id="details" class="details">
          <div class="container">

            <div class="row content">
              <div class="col-md-4 aos-init aos-animate" data-aos="fade-right">
                <img src="assets/img/details-1.png" class="img-fluid" alt="" />
              </div>
              <div class="col-md-8 pt-4 aos-init aos-animate" data-aos="fade-up">
                <h3>Terms And Conditions</h3>
                <p class="fst-italic">
                  Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern [Trinabh Payment Services Pvt Ltd]'s relationship with you in relation to this website.
                  The term '[Trinabh Pay]' or 'us' or 'we' refers to the owner of the website whose registered office is [Navi Mumbai Maharashtra].  The term 'you' refers to the user or viewer of our website.
                </p>
                <ul>
                  <li><i class="bi bi-check"></i> The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                  <li><i class="bi bi-check"></i> Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                  <li><i class="bi bi-check"></i>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                  <li><i class="bi bi-check"></i>  This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                </ul>
                <p>
                  All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.
                </p>
                <p>
                  We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time
                </p>
              </div>
            </div>






          </div>
        </section>


        <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 footer-info">
            <h3>TRINABH PAY</h3>
            <p>TRINABH PAY is a premier creative IT solutions & digital marketing company providing top of the line digital experiences using creative and innovative solutions of any complexity to a globally diverse clientele.</p>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="/Disclaimer">Disclaimer Policies</a></li>
              <li><a href="/refund">Security Policies</a></li>
              <li><a href="/Efund">Refund Policies</a></li>
              <li><a href="/terms">Terms and conditions</a></li>
              <li><a href="/privacy">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              10th Floor Office No 1004 NMS ICON 
Plot No 194 Sector 19 Ulwe Navi Mumbai ,Raigad , Maharashtra - 410206<br></br>
                          
              <strong>Email:</strong> trinabhpay@trinabhpay.in<br></br>
            </p>

            <div class="social-links">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>

          </div>

          <div class="col-lg-3 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Maharashtra.</p>
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>TRINABH PAYMENT SERVICES PVT LTD</strong>. All Rights Reserved
      </div>
      <div class="credits">

        Designed by <a href="">Trinabh Payment</a>
      </div>
    </div>
  </footer>







      </div>
    )
  }
}

export default Test;